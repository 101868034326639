/* ------------------------------
    Navbar active
------------------------------- */
/* Add active class in navbar according to current section */
$(window).on("scroll load resize", onScroll);
$('#menu .navbar-nav a[href^="#"]').on('click', function(e) {
    e.preventDefault();
    if ($(window).width() < 768) {
        $(".pmd-sidebar-overlay").trigger("click");
    }
    $(document).off("scroll");
    var target = this.hash,
        $target = $(target);
    var headerHeight = $(".navbar").height();
    if ($('body').hasClass('propeller-topbar-body')) {
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - headerHeight - 48
        }, 500, function() {
            $(document).on("scroll", onScroll);
        });
    } else {
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - headerHeight
        }, 500, function() {
            $(document).on("scroll", onScroll);
        });
    }
});
/* Get current section link */
function onScroll() {
    var scrollPos = $(document).scrollTop() + 205;
    $('#menu .nav > li > a').each(function() {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            $('.nav li a').removeClass("active");
            currLink.addClass("active");
            var spanTop = $('.active').offset().left - $('.nav').offset().left;
            var navWidth = $(this).outerWidth();
            $('.nav .nav-active').css({
                "left": spanTop,
                "width": navWidth
            });
        } else {
            currLink.removeClass("active");
        }
    });
}

/* ------------------------------
    Navbar active add remove
------------------------------- */
$(window).on("load", function() {
    var locationHas = location.hash;
    if (locationHas !== "") {
        scrollToSection(locationHas);
    }
});

function scrollToSection(locationHas) {
    $(window).scrollTop(0);
    var headerHeight = $(".navbar").height();
    $("body, html").animate({
        scrollTop: $(locationHas).offset().top - headerHeight
    }, 600);
}